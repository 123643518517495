import { render, staticRenderFns } from "./hubs_following_iyla_2.vue?vue&type=template&id=55f78e20&scoped=true&"
var script = {}
import style0 from "./hubs_following_iyla_2.vue?vue&type=style&index=0&id=55f78e20&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55f78e20",
  null
  
)

export default component.exports