<template>
  <div>
    <h2 class="mb-base text-left">Twitter Network Nanoanalysis :</h2>
    <!-- <div class="flex custom-card text-left"> -->
    <!-- <div class="vx-col w-full md:w-1/2"> -->
    <!-- <img src="@/nano_image/hubs_following_iyla_2.svg" width="100%" height="auto" /> -->
    <!-- <div style="width:100vw"> -->
    <Csvg />
    <!-- </div> -->
    <!-- </div> -->
    <!-- <div class="vx-col w-full md:w-1/2">
        <img src="https://jventures.pk/images/coming-soon.jpg" width="100%" />
    </div>-->
    <!-- </div> -->

    <div class="vx-row custom-card">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-2/3 mt-base">
        <!-- <div class="mt-base">
          <p class="text-left">Authority : {{data.definition.authority}}</p>
        </div>-->
        <div class="mt-base mb-base">
          <p class="text-left">Hub : {{data.definition.hub}}</p>
        </div>
      </div>
      <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-1/2 xl:w-1/3">
        <img src="../../../nano_image/Influencer_Charts.png" width="100%" />
        <!-- <h4>Nano </h4> -->

        <!-- <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-2/2 xl:w-2/2">
          <div class="vx-row">
            <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-1/4 xl:w-2/2 text-right">
              <div class="bg-blue"></div>
            </div>
            <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-3/4 xl:w-2/4 mt-4 text-left">
              <strong>0 to 300</strong>
            </div>
          </div>
        </div>
        <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-2/2 xl:w-2/2 mt-base">
          <div class="vx-row">
            <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-1/4 xl:w-2/2 text-right">
              <div class="bg-yellow"></div>
            </div>
            <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-3/4 xl:w-2/4 mt-4 text-left">
              <strong>300 to 600</strong>
            </div>
          </div>
        </div>

        <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-2/2 xl:w-2/2 mt-base">
          <div class="vx-row">
            <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-1/4 xl:w-2/2">
              <div class="bg-red"></div>
            </div>
            <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-3/4 xl:w-2/4 mt-4 text-left">
              <strong>600 to 5M</strong>
            </div>
          </div>
        </div>-->
      </div>
    </div>
    <div class="vx-row mt-base">
      <!-- <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-2/2 xl:w-2/2 text-left">
        <h2 class="text-warning">AUTHORITY:</h2>
        <twitterAuthorities :customData="data.authorities"></twitterAuthorities>
      </div>-->
      <div class="vx-col w-full sm:w-2/2 md:w-2/2 lg:w-2/2 xl:w-2/2 text-left">
        <h2 class="text-warning">HUBS :</h2>

        <twitterHub :customData="hubs"></twitterHub>
      </div>
    </div>
  </div>
</template>

<script>
import twitterAuthorities from "./twitterAuthorities.vue";
import twitterHub from "./twitterHub.vue";
import Csvg from "../../../nano_image/hubs_following_iyla_2.vue";
export default {
  data() {
    return {
      data: "",
      id: this.$route.params.username,
      hubs: []
    };
  },
  components: {
    twitterAuthorities,
    twitterHub,
    Csvg
  },
  mounted() {
    this.getData(this.id);
  },
  methods: {
    getData(name) {
      this.$http
        .get(`${process.env.VUE_APP_NOT_SECRET_CODE}/instagram/authority`)
        .then(res => {
          this.data = res.data;
          this.changeJsonType(this.data);
        });
    },
    changeJsonType(data) {
      for (let i in data.hub[0]) {
        this.hubs.push({
          username: i,
          color: data.hub[0][i].color,
          followers_count: data.hub[0][i].followers_count,
          followings_count: data.hub[0][i].followings_count,
          location: data.hub[0][i].location,
          name: data.hub[0][i].name,
          tweets_count: data.hub[0][i].tweets_count
        });
      }
    }
  }
};
</script>
<style >
.bg-red {
  background: red;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.bg-blue {
  background: blue;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.bg-yellow {
  background: yellow;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.custom-card > div {
  padding: 0 30px;
  /* border-right: 1px solid black;
  border-left: 1px solid black; */
  text-align: center;
}
.custom-card {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
  padding: 20px;
  background: white;
  min-height: 30px;
}
</style>